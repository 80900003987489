
const know = [{
        hash: "de4b598240e0c3980a725397b19b2483fca9a3c4",
        fileName: "1696cc762fd1efa41316217f4b733a3c29e020fe",
        title: "爬虫-puppeteer",
        filePath: "/blogs/know/",
        date: "Thu Sep 14 2023 09:40:44 GMT+0800 (中国标准时间)",
        description: "## 简介@@@  Puppeteer 是一个 Node 库，它提供了一个高级 API 来通过 DevTools 协议控制 Chromium 或 Chrome。Puppeteer 默认以 headless 模式运行，但是可以通过修改配置文件运行\"有头\"模式。 @@@  通常用来做为爬虫使用，但是只要与chrome有关的工作都可以使用，比如自动化测试，扩展测试等。@@@@@@## 示例@@@```js@@@  const puppeteer = require('puppeteer');@@@@@@  (async () => {@@@    const browser = await puppeteer.launch();@@@    const page = await browser.newPage();@@@    await page.goto('https://example.com');@@@    await page.screenshot({path: 'example.png'});@@@",
        outline: ["## 简介","## 示例","### 开启“有头”模式","### 复制操作","### 屏幕截图","### 元素操作","### 使用本地的chrome浏览器","## 更多"],
      },{
        hash: "5108b9bbe8ffd78e536d25f5d50406205c4f3114",
        fileName: "86b8f03d81d0c27da931c64e4bda9c750b3fabd3",
        title: "node进程守护-pm2",
        filePath: "/blogs/know/",
        date: "Tue Aug 15 2023 16:22:17 GMT+0800 (中国标准时间)",
        description: "## 简介@@@@@@  pm2 node的生产环境管理器（管理开发环境也行啊），用来守护node启动的服务@@@  pm2 有两种模式 fork（创建一个进程） 和 class（集群模式，IO的多路复用）@@@@@@**当前版本号5.1.2**@@@",
        outline: ["## 简介","## 安装","## 基础命令","## pm2 配置文件","### 配置项","## pm2 + nginx","## pm2.io：监控和诊断 Web 界面","## 更多"],
      },{
        hash: "e610e8892775d604cdd34a3ef1914e0726a44710",
        fileName: "f3f73e41f620f37bfd701fe40e29224a9cb8ab35",
        title: "node负载均衡测试工具-loadtest-artillery",
        filePath: "/blogs/know/",
        date: "Tue Aug 15 2023 16:20:10 GMT+0800 (中国标准时间)",
        description: "## loadtest@@@@@@方便的负载测试工具，支持`自定义header头`、`cookie`、`post参数`等@@@@@@安装 `npm i -g  loadtest`@@@",
        outline: ["## loadtest","## artillery","### 快速测试","### 使用配置文件","### 生成测试报告","## 更多"],
      },{
        hash: "906b52b478a6d89e47cdd00cb686f118a3435686",
        fileName: "6655c7a7969bc6bd895adc864d4a973c12b3855e",
        title: "音视频处理解决方案 - ffmpeg",
        filePath: "/blogs/know/",
        date: "Wed Aug 02 2023 11:56:28 GMT+0800 (中国标准时间)",
        description: "## FFmpeg@@@@@@A complete, cross-platform solution to record, convert and stream audio and video.@@@@@@一个完整的、跨平台的解决方案，记录、转换音频和视频@@@",
        outline: ["## FFmpeg","## 安装","## FFmpeg的使用格式","## 常用参数","## 用法","## ffmpeg-wasm","## 更多 "],
      },{
        hash: "7a013468a1466bd29cb01d6967c8f91c548904a4",
        fileName: "8735cd5d6e47b691a72b2d0e73f7556064a72cd0",
        title: "运行时监控系统 - fundebug",
        filePath: "/blogs/know/",
        date: "Sun Jun 25 2023 08:58:43 GMT+0800 (中国标准时间)",
        description: "## fundebug@@@@@@运行时监控系统，Fundebug 可以通过邮件或者第三方工具立即给开发者发送报警，这样能够帮助开发者及时发现并且修复应用 BUG，从而提升用户体验。@@@@@@**当前版本号2.8.0**@@@",
        outline: ["## fundebug","## 支持程度","## 安装","### react","### 小程序","## 常用属性","## Script Error","## status 0","## 一套基础配置","## 更多"],
      },{
        hash: "89f7c9f5cf1b7d0b7a55d9344a89b31c0aec8839",
        fileName: "6914c751886d2c08980583beaa6aedb6a980e6f8",
        title: "xpath用法与示例",
        filePath: "/blogs/know/",
        date: "Sat Jun 24 2023 16:56:08 GMT+0800 (中国标准时间)",
        description: "## xpath@@@@@@XPath 使用路径表达式来选取 XML 文档中的节点或节点集。节点是通过沿着路径 (path) 或者步 (steps) 来选取的。@@@@@@## 用法@@@",
        outline: ["## xpath","## 用法","### 选取节点","### 谓语（Predicates）","### 选取未知节点","### 选取若干路径","## xpath使用示例","## 案例","## 更多"],
      },{
        hash: "994ba2be4c86658f382722ac88fe86c136d416cb",
        fileName: "13d0eb56f2df5c753b348eee15b2feeae47479bc",
        title: "转换文案编码 - encoding",
        filePath: "/blogs/know/",
        date: "Tue Jun 20 2023 17:43:08 GMT+0800 (中国标准时间)",
        description: "## encoding@@@@@@encoding是iconv-lite的简单包装器，用于将字符串从一种编码转换为另一种编码。@@@@@@## 安装@@@",
        outline: ["## encoding","## 安装","## 用法","## 例子"],
      },{
        hash: "7772e4cc20162a96448d335f4b27aae10814a563",
        fileName: "3518753c1947250e1cab75867773e6ec7732e227",
        title: "git钩子工具 - husky",
        filePath: "/blogs/know/",
        date: "Fri Mar 03 2023 10:43:03 GMT+0800 (中国标准时间)",
        description: "## husky@@@@@@husky🐶，哈士奇。现代原生git钩子，支持linux、mac、windows@@@@@@进行git提交或推送时，可以使用它来整理**提交消息、运行测试、lint 代码**等@@@",
        outline: ["## husky","### 1.安装","### 2.初始化git钩子","### 3.新增prepare脚本","### 4.创建钩子命令","### 5.测试钩子","### 绕开钩子","### windows上的问题","## commitlint","### 安装并配置","## 更多"],
      },{
        hash: "1fcaa7b50aa5fcf5b239f1f492a72d4214acaaca",
        fileName: "8e860a1a36c91c19ad3a23aebb7e4791c903d15e",
        title: "web录制与回放 - rrweb",
        filePath: "/blogs/know/",
        date: "Tue Feb 14 2023 16:12:03 GMT+0800 (中国标准时间)",
        description: "## rrweb介绍@@@@@@rrweb是由smartx开源的web录制、回放基础库。它可以将⻚⾯中的 DOM 以及⽤户操作保存为可序列化的数据，以实现远程回放。@@@@@@## rrweb应用场景@@@",
        outline: ["## rrweb介绍","## rrweb应用场景","## 实现思路","## 应用","### 录制","### 回放","### 示例：","## 更多"],
      },{
        hash: "92472576c0b34b71122f8bd288acae94e968bcda",
        fileName: "218910252930db0d76af7079eb6064e99079c904",
        title: "抓包和代理神器 - whistle",
        filePath: "/blogs/know/",
        date: "Mon Dec 26 2022 09:35:58 GMT+0800 (中国标准时间)",
        description: "## 简介@@@whistle(读音 [ˈwɪsəl]，拼音 [wēisǒu] )是基于 Node 实现的跨平台抓包调试代理工具，有以下基本功能：@@@  * 查看 HTTP、HTTPS、HTTP2、WebSocket、TCP 请求响应数据@@@  * 修改 HTTP、HTTPS、HTTP2、WebSocket、TCP 请求响应数据@@@  * 修改请求 url、方法、头部、内容等@@@  * 修改响应状态码、头部、内容，并支持本地替换等@@@  * 修改 WebSocket 和 TCP 收发的帧数据@@@  * 设置 hosts（支持 IPv6）、http-proxy、https-proxy、socks@@@  * 作为HTTP代理或反向代理@@@  * 集成常用的 web 调试工具，如 weinre 和 log 等@@@  * 支持用 Node 编写插件扩展@@@@@@  **当前版本号2.7.21**@@@@@@## 安装@@@",
        outline: ["## 简介","## 安装","## 基本操作与配套设置","## 本地服务配置代理","## 配置规则","## 过滤条件设置","## Q&A","## 更多","## 关于chrome的跨域"],
      },{
        hash: "91f327b6973e4a871c858d4e2f5071d5fad19c1c",
        fileName: "ab89e82aeaa01ff8a6be30d70622b9764a691001",
        title: "react-router-v6",
        filePath: "/blogs/know/",
        date: "Mon May 09 2022 14:38:06 GMT+0800 (中国标准时间)",
        description: "## react-router-v6@@@@@@需要react 16.8+的环境，安装命令：`yarn add react-router-dom@6`@@@@@@## 示例@@@",
        outline: ["## react-router-v6","## 示例","## 取消了withRouter","## 使用`element`进行渲染","## 取消了<Switch>","## v6的路由匹配机制和二级路由","## v6如何添加404路由","## V6不再支持正则路由","## Route","## Link","## useRoutes - 路由配置","## useNavigate - 导航","## useMatch","## Outlet","## useOutletContext","## useSearchParams - 使用url上的参数","## 其他hooks","## 更多"],
      },{
        hash: "04643f5fa0849e4327bcba3253e91b7a3729a6c3",
        fileName: "31041137d49accf2f1cbe979687627d46b37135c",
        title: "不可变数据结构处理 - immer",
        filePath: "/blogs/know/",
        date: "Fri Apr 15 2022 14:14:51 GMT+0800 (中国标准时间)",
        description: "## immer@@@@@@**当前版本9.0.12**@@@@@@immer让我们以更方便的方式使用不可变状态。@@@",
        outline: ["## immer","## 安装","## 示例","## 术语","## 支持Map和Set","## react和immer","### immer","### use-immer","### redux+immer","## immer常见问题","## 更多"],
      },{
        hash: "5b8090bf387dc8df216a1f8998a44c04aafb434c",
        fileName: "2882213d7fdb6293f5853a38ac6ae8d1a303b9f4",
        title: "H5前端调试工具 - vConsole",
        filePath: "/blogs/know/",
        date: "Fri Apr 08 2022 11:05:02 GMT+0800 (中国标准时间)",
        description: "## Vconsole@@@@@@一个与框架无关的，针对手机网页的前端开发者调试面板。@@@@@@**当前版本号3.12.1**@@@",
        outline: ["## Vconsole","## 安装","## 使用","## 选项参数","## 销毁","## 设置开关按钮位置","## 使用插件","## 自定义插件","## 更多"],
      },{
        hash: "eabc97eee1cb9a608e4b2c524b3c7de3221354a7",
        fileName: "a2ff0519443119818698f0ba39dbd4f37865a149",
        title: "构建工具 - Rollup",
        filePath: "/blogs/know/",
        date: "Fri Apr 08 2022 11:04:21 GMT+0800 (中国标准时间)",
        description: "## Rollup@@@@@@  Rollup 是一个 JavaScript 模块打包器，可以将小块代码编译成大块复杂的代码，例如 library 或应用程序。 @@@  > tips：建议用rollup打包库，打包应用程序的话，webpack更专业一些@@@@@@  Rollup 对代码模块使用新的标准化格式，这些标准都包含在 JavaScript 的 ES6 版本中，而不是以前的特殊解决方案，如 CommonJS 和 AMD。@@@  > 意思就是说：Rollup支持打包ESM，webpack的话，是不支持打包ESM的@@@",
        outline: ["## Rollup","## 安装","## 示例","## Tree-Shaking 摇树","## 配置文件示例","## 兼容CommonJS","## 其他插件","## 常见问题","## 更多"],
      },{
        hash: "3e3cfdbbb17d4d51802efeccf338aa00aecd03cf",
        fileName: "e2e32548f21597f0e1f199ddcb2b39d04d8fd32f",
        title: "搭建项目中的组件文档 - storybook",
        filePath: "/blogs/know/",
        date: "Wed Feb 09 2022 09:13:23 GMT+0800 (中国标准时间)",
        description: "## storybook@@@@@@Storybook 是一个 UI 开发工具。它通过隔离组件使开发更快、更容易。这允许您一次处理一个组件。您可以开发整个 UI，而无需启动复杂的开发堆栈、将某些数据强制输入数据库或在应用程序中导航。@@@@@@> 简单说，Storybook可以让我们在项目启动一个组件文档，可以有效地防止多人开发重复造轮子的问题。当然还有其他的一些作用例如开发文档等，都可以实现@@@",
        outline: ["## storybook","## 安装","## 编写组件story示例","## 编写纯文档示例","## PropTypes","## 常见操作","## 更多"],
      },{
        hash: "e50c91428363efa7f078c53cc47bea5303fcae53",
        fileName: "783ce45fa081ce7e5c0f71f3e2eafe972568065b",
        title: "富文本编辑器内核 - slate",
        filePath: "/blogs/know/",
        date: "Thu Jan 06 2022 11:38:13 GMT+0800 (中国标准时间)",
        description: "## slate简介@@@  **当前版本号0.66.5**@@@@@@  >  slate is a completely customizable framework for building rich text editors.@@@  @@@  slate 是一个用于构建富文本编辑器的完全可定制的框架。@@@@@@  个人理解，slate可用来作为一个富文本编辑器内核，在此基础上，可以完全自主的开发自己的富文本编辑器@@@",
        outline: ["## slate简介","## 安装","## 常用核心概念","## 一些常用操作","### 指定编辑器获取焦点","### 编辑器全部选中","### 设置编辑器选区","### 给编辑器选区添加指定的标记","### 给编辑器选区删除指定的标记","### 获取指定属性的Node","### 把选区合并","### 替换文案","## slate架构","### model（模型）","### selection（选区）","### Transforms（变换）","### 插件机制","### slate-react","## 更多"],
      },{
        hash: "9db467b45747b76943038ab89ed9ec31e8476454",
        fileName: "8547bf4505a24b112e4f90140b65c4b43b6bdfa4",
        title: "函数式编程工具库 - ramdaJS",
        filePath: "/blogs/know/",
        date: "Thu Jan 06 2022 11:38:10 GMT+0800 (中国标准时间)",
        description: "## Ramda @@@@@@  > 一款实用的 JavaScript 函数式编程库 - 也是目前最理想的js函数式编程工具库。@@@@@@  > 与其他函数式的库相比，Ramda 的目标更为专注：专门为函数式编程风格而设计，更容易创建函数式 pipeline、且从不改变用户已有数据。@@@",
        outline: ["## Ramda ","## 安装","## 示例","## 部分API","## 应用场景","## 一些函数式编程中重要的概念","## 其他函数式工具库","## 更多"],
      },{
        hash: "255db67e2ef88f5a4e451d3b3161e6e10f0fe81e",
        fileName: "7e18a9b345fbda87051d9f878d7ad556813e735e",
        title: "现代应用管理手段-Docker",
        filePath: "/blogs/know/",
        date: "Thu Jan 06 2022 11:38:02 GMT+0800 (中国标准时间)",
        description: "@@@Docker是一种管理应用的现代手段，让应用管理变得可以预测和高效。@@@@@@简单讲，Docker不隔离计算， 只隔离环境。@@@",
        outline: ["## 什么是环境？什么是计算？","##  Docker是一种隔离技术而非虚拟化","## docker镜像与容器的关系","## 常用命令行","## 修改docker镜像的源","## docker - mysql","## 更多 "],
      },{
        hash: "97787d951c5925ca88c2410c65dbe27e500540f6",
        fileName: "ecd8beedef623f5b19f0586e357e47a6c979ffc9",
        title: "不可变数据合集 - immutable",
        filePath: "/blogs/know/",
        date: "Mon Dec 27 2021 14:54:11 GMT+0800 (中国标准时间)",
        description: "## 简介@@@@@@Immutable collections for JavaScript@@@@@@> JavaScript 的不可变集合@@@",
        outline: ["## 简介","## 安装","## 代码示例","### Map","### List","### merge和concat","### toJS","### fromJS","### 判断相等性","### 深度嵌套","### Lazy Seq","## 更多"],
      },{
        hash: "32992d08ce27a96e10a4d29d9998a65076ecfd20",
        fileName: "0ccafe9b9cd7f2ed8c276454a58d66a72adf8f20",
        title: "diff比较对象一致性 - microdiff",
        filePath: "/blogs/know/",
        date: "Fri Dec 10 2021 16:39:43 GMT+0800 (中国标准时间)",
        description: "## microdiff 简介@@@@@@Microdiff 是一个小型（目前<1kb）、快速、零依赖对象和数组比较库。它比大多数其他深度比较库要快得多，并且具有完整的 TypeScript 支持。@@@@@@## 使用@@@```js@@@  import diff from \"microdiff\";@@@",
        outline: ["## microdiff 简介","## 使用","## type","## 对数组的支持","## 为啥要用microdiff","## 更多"],
      },{
        hash: "8469f11dab13e35eb69b1b00ad4e9480506e71c1",
        fileName: "30acd349153ca5895325b119fac89f752cb59c7b",
        title: "前端mock数据 - mockjs",
        filePath: "/blogs/know/",
        date: "Fri Dec 10 2021 16:39:30 GMT+0800 (中国标准时间)",
        description: "## mockjs简介@@@@@@  Mock.js is a simulation data generator to help the front-end to develop and prototype separate from the back-end progress and reduce some monotony particularly while writing automated tests.@@@@@@  > Mock.js 是一个模拟数据生成器，帮助前端开发和原型与后端进程分离，减少一些单调，特别是在编写自动化测试时。@@@",
        outline: ["## mockjs简介","## 安装","## 示例","## 语法规范","### 数据模板","### 数据占位符","## 实用API","### Mock.mock( rurl?, rtype?, template|function(options) )","### Mock.Random","## 更多"],
      },{
        hash: "69f1b6e3237fce981947f14c4a7c331effa07686",
        fileName: "01102a9f94542b8ee92f341eb2a110f2f05f6015",
        title: "前端性能分析 - web-vitals",
        filePath: "/blogs/know/",
        date: "Fri Dec 10 2021 16:39:23 GMT+0800 (中国标准时间)",
        description: "## web-vitals简介@@@@@@web-vitals 是google chrome团队开发，用来协助开发者优化网站性能得工具，它会按照chrome的评判标准，直观的返回一些性能指标@@@@@@> react的脚手架cra中，默认安装了这个库@@@",
        outline: ["## web-vitals简介","## 安装","### 使用cdn引入","## 基本用法","### 示例 ","### 性能指标对象","## polyfill使用方法","## 注意事项","## 一次发送多个报告","## 配套设施","## 兼容性","## 限制","## 更多"],
      }]

export default know
